<template>
  <div class="header">
    <div class="inner">
      <div class="left">
        <router-link to="home" class="logo">
          <img src="./../assets/img/logo_user.png" alt="">
        </router-link>
        <div class="nav">
          <router-link to="home" class="active">首页</router-link>
          <router-link to="schoolList">了解大学</router-link>
          <router-link to="professionalList">了解专业</router-link>
          <router-link to="plannerList">名师规划</router-link>
          <router-link to="companyList">机构规划</router-link>
          <router-link to="helpList">常见问题</router-link>
          <router-link to="customerService">客服帮助</router-link>
        </div>
      </div>
      <div class="right">
        <router-link to="noticeList"><i class="bell el-icon-bell" is-dot></i></router-link>
        <div class="user-box" v-if="isLogin">
          <div class="avatar"><img :src="userinfo.avatar" ></div>
          <div class="user-info">
            <el-dropdown @command="handleClick">
              <div class="el-dropdown-link">
                <p >
                  {{ userinfo.nickname }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </p>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item style="font-size: 13px;color:#E6A23C;">刷新点:{{  userinfo.score  }}</el-dropdown-item>
                <el-dropdown-item style="color:#F56C6C;" command="layout">&nbsp;&nbsp;退出登录&nbsp;&nbsp;</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <span class="level">
            <img class="level-img" src="./../assets/img/jinpai2.png" alt="" v-if="userinfo.vlevel>0">
            <span class="s1">{{ userinfo.vlevel_name }}</span>
          </span>
          </div>
        </div>
        <div class="user-box" v-else>
          <el-button @click="goto_login" type="primary">登录/注册</el-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { userinfo } from "@/api/user.js";//引入
import { removeToken } from '@/utils/auth'
import Cookies from 'js-cookie'
export default {
  components:{
  },
  data() {
    return {
      isLogin:false,
      userinfo:{}
    }
  },
  created() {
    //用户信息
    this.getUserinfo();
  },
  methods: {
    goto_login() {
        this.$router.push({ name: 'login' });
    },
    async getUserinfo() {
				await userinfo().then(res => {
          console.log(res,'resreszererere')
          if(res.code == 1) {
            this.isLogin = true;
            this.userinfo = res.data;
          }
				}).catch(err=>{
					console.log(err);
				})
    },
    handleClick(command) {
        if(command == 'layout') {
					removeToken()
          this.$router.push({ name: 'login' });
        }
    },
  }
}
</script>

<style scoped lang="less">
.header{
  //border-bottom:1px solid #efefef;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.1);position: relative;
  &>.inner{
    display: flex;align-items: center;height:90px;justify-content: space-between;

  }
  .left{
    display: flex;
  }
  .logo{
    display: flex;align-items: center;
    img{
      height: 80px;
    }
  }
  .nav{
    display: flex;margin-left:20px;height:90px;
    a{
      color: #000;font-size: 14px;padding:0 30px;line-height: 90px;
      &.active{
        background: #0A9A78;color:#fff;
      }
    }
  }
  .right{
    display: flex;align-items: center;width:200px;
  }
  .bell{
    margin-right: 30px;font-size: 20px;color:#0A9A78;
  }
  .user-box{
    display: flex;align-items: center;
    .avatar{
      img{
        width: 42px;height: 42px;border-radius: 50%;
      }
    }
    .user-info{
      margin-left: 10px;
      p{
        width:100px;
        white-space: nowrap;/*强制在一行显示*/
        text-overflow:ellipsis;/*设置超出内容显示...*/
        overflow: hidden;/*一定不能少 超出的内容进行隐藏*/
        font-size: 14px;line-height: 1;margin-bottom: 4px;
        // color: #101010;
      }
      .level{
        color:#fff;line-height: 20px;font-size: 14px;background: #BBB094;border-radius: 8px;padding: 0 8px;
        display: inline-flex;align-items: center;justify-content: center;
        .level-img{
          width: 16px;height: 16px;margin-right: 4px;
        }
        .s1{
        }
      }
    }
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #0A9A78;
}
</style>