import request from "@/utils/request";//记得引入进来
 
export function userinfo() {
    return request({
        method: 'get',
        url: '/user/userinfo',
    })
}

export function authuser() {
    return request({
        method: 'get',
        url: '/user/authuser',
    })
}

export function plannerinfo() {
    return request({
        method: 'get',
        url: '/user/plannerinfo',
    })
}

export function changeprovince(params) {
    return request({
        method: 'post',
        url: '/user/changeprovince',
        data: params
    })
}

export function apply_planner1(params) {
    return request({
        method: 'post',
        url: '/user/apply_planner1',
        data: params
    })
}

export function apply_planner2(params) {
    return request({
        method: 'post',
        url: '/user/apply_planner2',
        data: params
    })
}

export function apply_planner3(params) {
    return request({
        method: 'post',
        url: '/user/apply_planner3',
        data: params
    })
}

export function apply_planner_company1(params) {
    return request({
        method: 'post',
        url: '/user/apply_planner_company1',
        data: params
    })
}

export function apply_planner_company2(params) {
    return request({
        method: 'post',
        url: '/user/apply_planner_company2',
        data: params
    })
}

export function apply_planner_company3(params) {
    return request({
        method: 'post',
        url: '/user/apply_planner_company3',
        data: params
    })
}

export function edit_planner(params) {
    return request({
        method: 'post',
        url: '/user/edit_planner',
        data: params
    })
}

export function logout_planner(params) {
    return request({
        method: 'post',
        url: '/user/logout_planner',
        data: params
    })
}

export function planner_info() {
    return request({
        method: 'get',
        url: '/user/planner_info',
    })
}

export function pay_result(params) {
    return request({
        method: 'post',
        url: '/user/pay_result',
        data: params
    })
}

export function pay_good_result(params) {
    return request({
        method: 'post',
        url: '/user/pay_good_result',
        data: params
    })
}

export function pay_vip_result(params) {
    return request({
        method: 'post',
        url: '/user/pay_vip_result',
        data: params
    })
}

export function pay_recharge_result(params) {
    return request({
        method: 'post',
        url: '/user/pay_recharge_result',
        data: params
    })
}

export function pay_card_result(params) {
    return request({
        method: 'post',
        url: '/user/pay_card_result',
        data: params
    })
}

export function pay_status(params) {
    return request({
        method: 'post',
        url: '/user/pay_status',
        data: params
    })
}

export function submit_good_form(params) {
    return request({
        method: 'post',
        url: '/user/submit_good_form',
        data: params
    })
}

export function viplege_list() {
    return request({
        method: 'get',
        url: '/user/viplege_list'
    })
}

export function notice_list() {
    return request({
        method: 'get',
        url: '/user/notice_list'
    })
}

export function changemobile(params) {
    return request({
        method: 'post',
        url: '/user/changemobile',
        data: params
    })
}

export function setmobile(params) {
    return request({
        method: 'post',
        url: '/user/setmobile',
        data: params
    })
}

export function edit_user(params) {
    return request({
        method: 'post',
        url: '/user/edit_user',
        data: params
    })
}

export function edit_user_wish(params) {
    return request({
        method: 'post',
        url: '/user/edit_user_wish',
        data: params
    })
}

export function notice_table_list(params) {
    return request({
        method: 'post',
        url: '/user/notice_table_list',
        data: params
    })
}

export function notice_detail(params) {
    return request({
        method: 'post',
        url: '/user/notice_detail',
        data: params
    })
}

export function notice_table_params() {
    return request({
        method: 'post',
        url: '/user/notice_table_params',
    })
}

export function goods_form_list(params) {
    return request({
        method: 'post',
        url: '/user/goods_form_list',
        data: params
    })
}

export function goods_form_planner_list(params) {
    return request({
        method: 'post',
        url: '/user/goods_form_planner_list',
        data: params
    })
}

export function goods_form_planner_info(params) {
    return request({
        method: 'post',
        url: '/user/goods_form_planner_info',
        data: params
    })
}

export function create_rate(params) {
    return request({
        method: 'post',
        url: '/user/create_rate',
        data: params
    })
}

export function create_intervene(params) {
    return request({
        method: 'post',
        url: '/user/create_intervene',
        data: params
    })
}

export function over_order(params) {
    return request({
        method: 'post',
        url: '/user/over_order',
        data: params
    })
}

export function planner_over_order(params) {
    return request({
        method: 'post',
        url: '/user/planner_over_order',
        data: params
    })
}

export function score_table_list(params) {
    return request({
        method: 'post',
        url: '/user/score_table_list',
        data: params
    })
}

export function price_table_list(params) {
    return request({
        method: 'post',
        url: '/user/price_table_list',
        data: params
    })
}

export function card_table_list(params) {
    return request({
        method: 'post',
        url: '/user/card_table_list',
        data: params
    })
}

export function recommend_table_list(params) {
    return request({
        method: 'post',
        url: '/user/recommend_table_list',
        data: params
    })
}

export function withdrawal_table_list(params) {
    return request({
        method: 'post',
        url: '/user/withdrawal_table_list',
        data: params
    })
}

export function goods_table_list(params) {
    return request({
        method: 'post',
        url: '/user/goods_table_list',
        data: params
    })
}

export function add_goods(params) {
    return request({
        method: 'post',
        url: '/user/add_goods',
        data: params
    })
}

export function edit_goods(params) {
    return request({
        method: 'post',
        url: '/user/edit_goods',
        data: params
    })
}

export function delete_goods(params) {
    return request({
        method: 'post',
        url: '/user/delete_goods',
        data: params
    })
}

export function planner_rate_table_list(params) {
    return request({
        method: 'post',
        url: '/user/planner_rate_table_list',
        data: params
    })
}

export function create_problem(params) {
    return request({
        method: 'post',
        url: '/user/create_problem',
        data: params
    })
}

export function create_withdrawal(params) {
    return request({
        method: 'post',
        url: '/user/create_withdrawal',
        data: params
    })
}

export function card_params() {
    return request({
        method: 'get',
        url: '/user/card_params'
    })
}

export function recommend_params() {
    return request({
        method: 'get',
        url: '/user/recommend_params'
    })
}

export function apply_area_list() {
    return request({
        method: 'get',
        url: '/user/apply_area_list'
    })
}

export function edit_card(params) {
    return request({
        method: 'post',
        url: '/user/edit_card',
        data: params
    })
}

export function pastwish_table_list(params) {
    return request({
        method: 'post',
        url: '/user/pastwish_table_list',
        data: params //可以简写为data
    })
}

export function save_plan(params) {
    return request({
        method: 'post',
        url: '/user/save_plan',
        data: params //可以简写为data
    })
}

export function wishplan_table_list(params) {
    return request({
        method: 'post',
        url: '/user/wishplan_table_list',
        data: params //可以简写为data
    })
}

export function del_plan(params) {
    return request({
        method: 'post',
        url: '/user/del_plan',
        data: params //可以简写为data
    })
}

export function wishplan_info(params) {
    return request({
        method: 'post',
        url: '/user/wishplan_info',
        data: params //可以简写为data
    })
}

export function pastwish_info_list(params) {
    return request({
        method: 'post',
        url: '/user/pastwish_info_list',
        data: params //可以简写为data
    })
}

export function save_plan_edit(params) {
    return request({
        method: 'post',
        url: '/user/save_plan_edit',
        data: params //可以简写为data
    })
}