<template>
  <div>
    <div class="header-top">
      <div class="inner">
        <div class="left">
          <div class="logo" @click="goto_home">
            <img src="./../assets/img/logo_03.jpg" alt="">
          </div>
          <a @click="openCityChoose" href="javascript:;" class="ads-btn">{{province_shortname || '未选择省份'}}<i class="el-icon-sort"></i></a>
        </div>
        <div class="right">
          <!-- <el-button>用户中心</el-button> -->
          <div v-if="isLogin">
            <el-dropdown @command="handleClick">
              <div class="el-dropdown-link">
                <el-avatar style="vertical-align: middle;" :size="35" :src="userinfo.avatar" @error="errorHandler">
                  <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>
                </el-avatar>
                <span style=" display: inline-block; vertical-align: middle;margin-left: 10px;font-size: 16px;">
                  {{ userinfo.nickname }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                  <br>
                  <span style="font-size: 13px;color:#E6A23C;">刷新点:{{  userinfo.score  }}</span>
                </span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item style="color:#409EFF;" command="user">用户中心</el-dropdown-item>
                <el-dropdown-item style="color:#F56C6C;" command="layout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <el-button v-else @click="goto_login">登录/注册</el-button>
        </div>
      </div>
    </div>
    <div class="header-bottom">
      <div class="nav inner">
        <el-menu :default-active="activeIndex" mode="horizontal" router background-color="#0A9A78" text-color="#fff">
          <el-menu-item index="home">首页</el-menu-item>
          <el-submenu :index="item.id" v-for="(item, index) in wishtypeList" :key="index">
            <template slot="title">{{ item.title }}</template>
            <el-menu-item :index="'volunteerList?wishtype='+item2.id" v-for="(item2, index2) in item.children" :key="index2">{{ item2.title }}</el-menu-item>
          </el-submenu>
          <el-menu-item index="plannerList" >名师规划</el-menu-item>
          <el-menu-item index="companyList" >机构规划</el-menu-item>
          <el-menu-item index="helpList" >常见问题</el-menu-item>
          <el-menu-item index="customerService" >客服帮助</el-menu-item>
        </el-menu>
      </div>
    </div>
    <cityChooseModal v-if="cityChooseVisible" @cancel="closeCityChoose"></cityChooseModal>

  </div>
</template>

<script>
import cityChooseModal from "@/components/cityChooseModal.vue";
import { header } from "@/api/index.js";//引入
import { userinfo } from "@/api/user.js";//引入
import { removeToken } from '@/utils/auth'
import Cookies from 'js-cookie'
export default {
  components:{
    cityChooseModal
  },
  data() {
    return {
      province_id:null,
      province_name:null,
      province_shortname:null,
      activeIndex: '1',
      cityChooseVisible: false,
      isLogin:false,
      userinfo:{},
      wishtypeList:[]
    }
  },
  created() {
    //判断是否选择了省份
    if (!Cookies.get('province_id')) {
      this.cityChooseVisible = true;
    }
    this.province_id =  Cookies.get('province_id');
    this.province_name =  Cookies.get('province_name');
    this.province_shortname =  Cookies.get('province_shortname');
    //判断是否有推荐人
    let params = this.$route.query;
    if(params.recommend_id) {
      let recommend_id = params.recommend_id;
      Cookies.set('recommend_id', recommend_id)
    }
    //用户信息
    this.getUserinfo();
    //获取公共信息
    this.loadData();
  },
  methods: {
    goto_home() {
      if (this.$router.currentRoute.path !== '/home') {
        this.$router.push({ name: 'home' });
      }
    },
    goto_login() {
        this.$router.push({ name: 'login' });
    },
    handleClick(command) {
        if(command == 'user') {
          this.$router.push({ name: 'userInfo' });
        }
        if(command == 'layout') {
					removeToken()
          this.$router.push({ name: 'login' });
        }
    },
    errorHandler() {
      return true
    },
    openCityChoose(){
      this.cityChooseVisible = true;
    },
    closeCityChoose(){
      this.cityChooseVisible = false;
    },
    async loadData()  {
      let province_id =  Cookies.get('province_id') ?? 0;
      let obj = {
        province_id : province_id
      }
      await header(obj).then(res => {
        console.log(res,'resreszererere')
        if(res.code == 1) {
          let resData = res.data;
          this.wishtypeList = resData.wishtype;
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    async getUserinfo() {
				await userinfo().then(res => {
          console.log(res,'resreszererere')
          if(res.code == 1) {
            this.isLogin = true;
            this.userinfo = res.data;
          }
				}).catch(err=>{
					console.log(err);
				})
    }
  }
}
</script>

<style scoped lang="less">
.header-top{
  padding:18px 0;
  .inner{
    margin:0 auto;display: flex;justify-content: space-between;align-items: center;
    &>.left{
      display: flex;align-items: center;
    }
  }
  .logo{
    margin-right:80px;
    img{
      height: 60px;
    }
  }
  .ads-btn{
    font-size: 20px;color:#000;text-decoration: none;font-weight: bold;
    .el-icon-sort{
      transform: rotate(90deg);margin-left:2px;
    }
  }
  .right{
    display: flex;
    .el-button{
      margin-left: 36px;background: #0A9A78 100%;color: #fff;border: none;border-radius: 14px;
    }
  }
}

.header-bottom{
  background: #0A9A78;
  .inner{margin:0 auto;}
  /deep/.el-menu,.el-menu--horizontal{
    background: #0A9A78 100%;border-bottom: none !important;
    .el-menu-item,.el-submenu__title{
      color:#fff !important;opacity: 1 !important;background: #0A9A78 100%;
    }
    .is-active{
      color:#fff !important;border-bottom: 2px solid #FFCA42;
    }
    .el-menu-item:not(.is-disabled):focus, .el-menu-item:not(.is-disabled):hover, .el-submenu .el-submenu__title:hover {
      background-color: #005944;
    }
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #0A9A78;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
